<template>
  <LayoutAdmin>
    <Dashboard />
  </LayoutAdmin>
</template>

<script>
import LayoutAdmin from "@/Layouts/LayoutAdmin.vue";
import Dashboard from "@/components/Dashboard.vue";
export default {
  data: () => ({}),
  components: {
    LayoutAdmin,
    Dashboard,
  },
};
</script>

<style scoped>
</style>