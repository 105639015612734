<template>
  <div class="layoutImport">
    <div class="msgStatus">
      <v-alert v-if="errorMsg == 'Acesso negado'" type="error">{{
        errorMsg
      }}</v-alert>
      <v-alert v-if="successMsg != ''" type="success">{{ successMsg }}</v-alert>
    </div>
    <v-overlay v-if="loading != ''">
      <div class="loadingImport">
        <v-progress-circular
          v-if="loading != ''"
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </v-overlay>
    <h1>Dashboard de importação - UOL</h1>
    <div class="importacao">
      <div class="titleImportacao">
        <span>Importação</span>
      </div>
      <div class="contentImportacao">
        <div class="qtdDias">
          <v-text-field label="Dias" v-model="validate" dense></v-text-field>
        </div>
        <div class="selectImport text-center">
          <span>{{ nomeArquivo }}</span>
          <v-btn @click="$refs.inputFile.click()" color="primary" large>
            Escolha o arquivo</v-btn
          >
          <input
            label="Arquivo"
            ref="inputFile"
            type="file"
            dense
            @change="onFileSelect"
            style="display: none"
          />
        </div>
        <div class="btnImportar">
          <v-btn color="success" @click="onUpload" depressed elevation="2" large
            >Importar</v-btn
          >
        </div>
      </div>
    </div>
    <div class="tableResult">
      <div class="titleTable">
        <span>Lotes</span>
      </div>
      <div class="contentTable">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center fontSizeTh">Usuário</th>
                <th class="text-center fontSizeTh">Validade</th>
                <th class="text-center fontSizeTh">Criação do arquivo</th>
                <th class="text-center fontSizeTh">Nome do arquivo</th>
                <th class="text-center fontSizeTh">Status</th>
                <th class="text-center fontSizeTh">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in dadosImportacao" :key="item.id">
                <td class="text-center fontSizeTable">{{ item.usuario }}</td>
                <td class="text-center">{{ item.validade }}</td>
                <td class="text-center">{{ item.created_at }}</td>
                <td class="text-center">
                  {{ item.arquivo_original }}
                </td>
                <td class="text-center">{{ item.status }}</td>
                <td class="text-center">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="iconDelete"
                        color="#dc3545"
                        @click="deletarArquivo(item.id)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-close-circle-outline
                      </v-icon>
                    </template>
                    <span>Deletar Inativo</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    selectedFile: null,
    validate: "",
    loading: "",
    errorMsg: "",
    successMsg: "",
    nomeArquivo: "",
    dadosImportacao: [],
  }),
  methods: {
    dadosAtualizados() {
      let KeyJwt = localStorage.getItem("jwt");
      axios.get(`${this.$url}/dashboard/lotes?jwt=${KeyJwt}`).then((resp) => {
        this.dadosImportacao = resp.data;
      });
      (this.validate = ""), (this.nomeArquivo = "");
    },
    onFileSelect(event) {
      this.selectedFile = event.target.files[0];
      this.nomeArquivo = event.target.files[0].name;
      /*   console.log(this.nomeArquivo); */
    },
    onUpload() {
      this.loading = "loading";
      const fd = new FormData();
      let KeyJwt = localStorage.getItem("jwt");
      fd.append("arquivoCsv", this.selectedFile);
      fd.append("jwt", KeyJwt);
      fd.append("validate", this.validate);
      axios
        .post(
          `${this.$url}/dashboard/import`,
          fd
          /* , {
          //    Monitora o envio do arquivo até a api   
          onUploadProgress: (uploadEvent) => {
                this.porcentagem = (uploadEvent.loaded / uploadEvent.total) * 100;
            console.log(
              "Upload" +
                Math.round((uploadEvent.loaded / uploadEvent.total) * 100) +
                "%"
            );
          },
        }*/
        )
        .then((resp) => {
          this.loading = resp.data;
          this.errorMsg = resp.data.error;
          this.successMsg = resp.data.true;

          setTimeout(() => {
            this.errorMsg = "";
            this.successMsg = "";
          }, 2500);

          console.log(resp.data.true);
          if (resp.data.true != "") {
            this.loading = "";
          }
          this.dadosAtualizados();
        });
    },
    deletarArquivo(idLote) {
      this.loading = "loading";
      this.dialog = true;
      let token = localStorage.getItem("jwt");
      fetch(`${this.$url}/dashboard/inativa_clientes`, {
        method: "POST",
        body: JSON.stringify({
          jwt: token,
          idLote: idLote,
        }),
      })
        .then((response) => response.json())
        .then((json) => {
          this.loading = "";

          let KeyJwt = localStorage.getItem("jwt");
          axios
            .get(`${this.$url}/dashboard/lotes?jwt=${KeyJwt}`)
            .then((resp) => {
              this.dadosImportacao = resp.data;
            });
        });
    },
  },
  mounted() {
    let KeyJwt = localStorage.getItem("jwt");
    axios.get(`${this.$url}/dashboard/lotes?jwt=${KeyJwt}`).then((resp) => {
      this.dadosImportacao = resp.data;
    });
  },
};
</script>

<style scoped>
.layoutImport {
  padding: 30px 0px;
  width: 100%;
}
h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #2d2f50;
}
.importacao {
  background-color: white;
  width: 60%;
  margin: 0 auto;
  border-radius: 8px;
}

.titleImportacao {
  background-color: #1457ed;
  border-radius: 8px 8px 0px 0px;
  padding: 8px;
  text-align: center;
}
.titleImportacao span {
  color: white;
  font-size: 22px;
}

.contentImportacao {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 120px;
}
.qtdDias {
  width: 40px;
  margin-top: 3px;
}
.selectImport {
  width: 200px;
}
.tableResult {
  background-color: white;
  width: 60%;
  margin: 0 auto;
  border-radius: 8px;
  margin-top: 40px;
}
.fontSizeTh {
  font-size: 17px !important;
}
.titleTable {
  background-color: #1457ed;
  border-radius: 8px 8px 0px 0px;
  padding: 8px;
  text-align: center;
}
.titleTable span {
  color: white;
  font-size: 22px;
}
.iconDelete {
  cursor: pointer;
}

@media (max-width: 1300px) {
  .importacao {
    width: 78%;
  }
  .tableResult {
    width: 78%;
  }
}
.loadingImport {
  position: absolute;
}
.msgStatus {
  position: absolute;
  right: 20px;
}
</style>